import axios from "axios";

export interface Filter {
  field: string | string[];
  operator: string;
  value?: string | string[] | number | Date | boolean | null | object;
  record?: any;
  type?: string;
  search?: "and" | "or";
}

export interface Filters {
  rules: Filter[];
}

export interface Query {
  [key: string]: string;
}

interface GetAll {
  responseData: string;
  page?: number;
  accumulatedRecords?: any[];
}

export interface getParams {
  queries?: Query;
  id?: string;
  filters?: Filters;
  cid?: string;
  getAll?: GetAll;
}

export interface putParams {
  formBody: any;
  id?: string;
  cid?: string;
}

export interface postParams {
  formBody: any;
  id?: string;
}

/* eslint-disable */
export default (route: string, childRoute?: string) => {
  const get = async (params: getParams): Promise<any> => {
    const { getAll, ...genericParams } = params;
    const { queries, id, filters, cid } = genericParams;

    let url = `/api/${route}`;

    id && (url += `/${id}`);

    childRoute && (url += `/${childRoute}`);

    cid && childRoute && (url += `/${cid}`);

    let page = getAll?.page || 1;
    let accumulatedRecords = getAll?.accumulatedRecords || [];
    let responseData = getAll?.responseData || "";

    if (queries || getAll) {
      let queryList: string[] = [];
      queries &&
        Object.keys(queries).forEach((key: string) => {
          // when doing get all - ignore limit and page queries
          if (getAll && ["limit", "page"].includes(key)) return;

          queryList.push(`${key}=${queries[key]}`);
        });

      if (getAll) {
        queryList.push(`limit=1000`);
        queryList.push(`page=${page}`);
      }

      url += `?${queryList.join("&")}`;
    }

    if (filters) {
      const querySymbol = url.includes("?") ? "&" : "?";
      url += `${querySymbol}filters=${encodeURIComponent(JSON.stringify(filters))}`;
    }

    const response = await axios.get(url);

    const records = response.data;

    if (getAll) {
      accumulatedRecords.push(...records[responseData]);

      // if more records in the collection than what we have retrieved so far, retrieve the records starting at the next page
      if (accumulatedRecords.length < records.totalRecords) {
        page++;
        const getSettings: getParams = {
          ...genericParams,
          getAll: {
            page,
            responseData,
            accumulatedRecords,
          },
        };
        return await get(getSettings);
      }

      return {
        success: true,
        [responseData]: accumulatedRecords,
        totalRecords: records.totalRecords,
      };
    }

    return response.data;
  };

  const put = async ({ formBody, id, cid }: putParams) => {
    let url = `/api/${route}`;

    id && (url += `/${id}`);

    childRoute && (url += `/${childRoute}`);

    cid && childRoute && (url += `/${cid}`);

    const response = await axios({
      method: "PUT",
      url,
      data: formBody,
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  };

  const post = async ({ formBody, id }: postParams) => {
    let url = `/api/${route}`;

    childRoute && id && (url += `/${id}`);

    childRoute && (url += `/${childRoute}`);

    const response = await axios({
      method: "POST",
      url,
      data: formBody,
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  };

  const remove = async (id: string, additionalParams?: Record<string, string>) => {
    let url = `/api/${route}/${id}`;

    childRoute && (url += `/${childRoute}`);

    if (additionalParams) {
      const queryParams = new URLSearchParams(additionalParams).toString();
      url += `?${queryParams}`;
    }

    const response = await axios({
      method: "DELETE",
      url,
    });

    return response.data;
  };

  return {
    get,
    put,
    delete: remove,
    post,
  };
};
