import { userTablePreferencesCrud } from "./cruds";

const getTablePreference = async (userId: string) => {
  try {
    const response = await userTablePreferencesCrud.get({
      id: userId,
    });

    return response;
  } catch (error) {
    console.error("Unable to get Table Preference", error);
  }
};

const updateTablePreference = async (userId: string, tablePreferenceId: string, data: any) => {
  try {
    const response = await userTablePreferencesCrud.put({
      formBody: data,
      id: userId,
      cid: tablePreferenceId,
    });

    return response;
  } catch (error) {
    console.error("Unable to update Table Preference", error);
  }
};

const createTablePreference = async (userId: string, data: any) => {
  try {
    const response = await userTablePreferencesCrud.post({
      formBody: data,
      id: userId,
    });

    return response;
  } catch (error) {
    console.error("Unable to create Table Preference", error);
  }
};

export { getTablePreference, updateTablePreference, createTablePreference };
